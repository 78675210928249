import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";

import Svg from "../../Atoms/Svg";
import Link from "../../Atoms/Link";
import Popin from "../Popin";
import RichText from "../../Atoms/RichText";
import { adapter as footerAdapter } from "../../Molecules/Footer";

import { Noto, NotoSmall, NotoBody } from "../../Atoms/Typography/Typography";
import {
  scaleSpacing,
  colors,
  mediaquery,
  rem,
  getFooterLogos,
} from "../../../../styles/theme";

const NotoThin = css`
  ${Noto};
  font-size: ${rem(13)};
  line-height: ${rem(18)};
  font-weight: 300;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--external-gutter);
  padding-bottom: calc(var(--external-gutter) / 2);
  background-color: ${colors.black};
  color: ${colors.white};

  ${mediaquery.md(css`
    align-items: center;
  `)};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${mediaquery.md(css`
    width: auto;
    margin-top: 0;
    justify-content: center;
  `)};
`;

const LogoWrapper = styled.div`
  width: 171px;
  margin-top: ${scaleSpacing(6)};

  img {
    display: block;
    width: 100%;
  }

  ${mediaquery.md(css`
    margin-top: ${scaleSpacing(8)};
  `)};
`;

const Logos = styled.div`
  width: 100%;
  padding-top: ${scaleSpacing(9)};
  padding-bottom: ${scaleSpacing(6)};
  display: flex;
  flex-direction: column;

  ${mediaquery.md(css`
    flex-direction: row;
    justify-content: center;
  `)};
`;

const StyledImageLink = styled(Link)`
  display: block;
  margin-right: ${scaleSpacing(5)};

  &:last-child {
    margin-right: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  ${mediaquery.md(css`
    margin: 0 ${scaleSpacing(5)};
  `)};
`;

const SocialMedia = styled.div`
  width: 100%;
  padding: ${scaleSpacing(8)} 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: thin solid #333333;
  border-bottom: thin solid #333333;
  color: ${colors.white};
  ${NotoThin};

  p {
    margin-right: ${scaleSpacing(4)};
  }

  ${mediaquery.md(css`
    justify-content: center;

    p {
      margin-right: ${scaleSpacing(9)};
    }
  `)};
`;

const Links = styled.div`
  width: 100%;
  padding-top: ${scaleSpacing(8)};
  padding-bottom: ${scaleSpacing(8)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    ${NotoThin};
    font-weight: 500;
    color: ${colors.white};
    line-height: ${scaleSpacing(10)};
  }

  ${mediaquery.md(css`
    justify-content: center;
    flex-direction: row;

    a {
      margin: 0 ${scaleSpacing(4)};
    }
  `)};
`;

const CopyrightWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: ${scaleSpacing(5)};
  color: ${colors.mediumgrey1};

  p,
  a {
    ${NotoSmall};
    font-size: ${rem(11)};
    margin-right: ${scaleSpacing(4)};
    color: ${colors.mediumgrey1};
    text-decoration: none;
  }

  ${mediaquery.md(css`
    justify-content: space-between;
    p,
    a {
      margin: 0;
    }
  `)};
`;

const PopinStyles = css`
  color: ${colors.black};

  p {
    margin-bottom: ${scaleSpacing(0.5)};
    ${NotoBody};
  }
`;

const Footer = ({ siteTheme, datamock }) => {
  const [showLegalsMentionPopin, setshowLegalsMentionPopin] = useState(false);
  const contextTheme = useTheme();
  const theme = siteTheme || contextTheme;
  const footerData = !datamock ? footerAdapter() : datamock;
  const footerLogos = getFooterLogos(theme.label, footerData);

  const {
    legalsMentionPopinDetails,
    legalsLabel,
    legalsLink,
    mainLinks,
    socialTitle,
    socialLinks,
    copyrightLabel,
  } = footerData || {};

  return (
    <Wrapper>
      <LogoWrapper>
        <Link link={{ url: "/" }}>
          <Svg name={theme?.logo} size={171} height={39} fill={colors.white} />
        </Link>
      </LogoWrapper>

      {!!mainLinks && (
        <Links>
          {mainLinks.map(({ label, link, isLegalsMention }, index) => (
            <Link
              key={index}
              link={link}
              {...(isLegalsMention &&
                !!legalsMentionPopinDetails && {
                  onClick: e => {
                    e.preventDefault();
                    setshowLegalsMentionPopin(true);
                  },
                })}
            >
              {label}
            </Link>
          ))}
        </Links>
      )}

      {!!legalsMentionPopinDetails && (
        <Popin
          shown={showLegalsMentionPopin}
          hide={() => setshowLegalsMentionPopin(false)}
          css={PopinStyles}
        >
          <RichText data={legalsMentionPopinDetails} />
        </Popin>
      )}

      {!!socialLinks && (
        <SocialMedia>
          {socialTitle && <p>{socialTitle}</p>}
          <Row
            css={css`
              width: auto;
              justify-content: flex-end;
            `}
          >
            {socialLinks.map(({ type, link }, index) => (
              <StyledImageLink key={index} link={link}>
                <Svg
                  name={type}
                  size={24}
                  height={24}
                  fill={colors.mediumgrey1}
                />
              </StyledImageLink>
            ))}
          </Row>
        </SocialMedia>
      )}

      <Logos>
        {footerLogos.map((row, i) => (
          <Row
            key={i}
            css={css`
              margin-top: ${scaleSpacing(i !== 0 ? 10 : 0)};
            `}
          >
            {row.map((logo, j) => (
              <StyledImageLink
                key={j}
                link={logo.link}
                css={
                  logo.label == "troiscouleurs"
                    ? css`
                        ${mediaquery.md(css`
                          position: relative;
                          top: ${scaleSpacing(0.75)};
                        `)};
                      `
                    : ``
                }
              >
                <Svg
                  name={logo.label}
                  size={logo.width}
                  height={logo.height}
                  fill={logo.fill}
                />
              </StyledImageLink>
            ))}
          </Row>
        ))}
      </Logos>

      <CopyrightWrapper>
        <p>{copyrightLabel}</p>
        <Link link={legalsLink}>{legalsLabel}</Link>
      </CopyrightWrapper>
    </Wrapper>
  );
};

Footer.propTypes = {
  siteTheme: PropTypes.string,
  datamock: PropTypes.object,
};

export default React.memo(Footer);
